@import './theme';
@import '~vizzuality-components/dist/bundle';

.mapboxgl-ctrl-bottom-left .mapboxgl-ctrl {
  padding-left: 20px;
}

// .mapboxgl-ctrl-bottom-right {
//   right: 80px;
// }

.mapboxgl-ctrl-attrib-inner > a {
  font-size: $fontXXSmall;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  background: transparent;
}

.MuiTypography-body1 {
  font-size: $fontSmall !important;
  font-family: $primaryFontFamily !important;
}

.MuiFormControlLabel-root {
  margin: 0;
}

html {
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

html::-webkit-scrollbar {
  display: none;
}

body {
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  font-size: 0.875rem;
  font-family: var(--primary-font-family) !important;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  // background-color: #c8def4;
}

.theme-light,
.theme-dark {
  background-color: $backgroundColor;
}

.MuiInput-underline:after {
  border-bottom: 2px solid $primaryColor !important;
}

:focus {
  outline: none !important;
}

.planet-links {
  color: $primaryColor;
}

.planet-links:hover {
  color: $primaryColor;
}

.planet-links:visited {
  color: $primaryColor;
}

:export {
  primaryColor: $primaryColor;
  primaryFontColor: $primaryFontColor;
}

a {
  text-decoration: none;
}

// Reseting User agent styles to maintain cross browser interferences
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

// Deforestation slider
.rc-slider-mark-text {
  font-family: $primaryFontFamily;
  color: $primaryFontColor;
  font-size: $fontXSmall;
}

// Stepper Connector
.MuiStepIcon-root.MuiStepIcon-active,
.MuiStepIcon-root.MuiStepIcon-completed {
  color: $primaryColor !important;
}

.comparison-map {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
}

.mapboxgl-compare {
  top: 0;
  position: fixed;
}

.mapboxgl-compare .compare-swiper-vertical {
  background-color: $primaryColor;
}

// .mapboxgl-ctrl-bottom-right {
//   margin: 0 10px 40px 0 !important;
// }

// .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-attrib-inner {
//   display: none;
// }

@font-face {
  font-family: 'Pampers Script';
  src: url('/tenants/pampers/Pampers Script.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HarmoniaSansPro';
  src: url('/tenants/pampers/HarmoniaSansPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
.unDecadeFill {
  fill: $undecadeFill;
}

.iconFillNone {
  fill: none;
  stroke: $primaryFontColor;
}

.iconFillColor {
  fill: $primaryFontColor;
}

.backTO {
  width: 234px;
  height: 54px;
  border-radius: 80px;
}

.primaryButton,
.secondaryButton {
  color: $light;
  font-weight: bold;
  background-color: $primaryColor;
  background-image: linear-gradient(
    103deg,
    $primaryColor 5%,
    $primaryDarkColor 116%
  );
  height: 46px;
  padding: 0px;
  text-align: center;
  border: 0px;
  border-radius: 52px;
  min-width: 100px;
  width: 234px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  text-decoration: none;
}
.primaryButton:hover,
.secondaryButton:hover {
  transform: translateY(-7px);
  cursor: pointer;
}

.secondaryButton {
  background-image: none;
  background-color: $backgroundColorDark;
  color: $primaryFontColor;
  p {
    margin-left: 6px;
  }
  svg {
    height: 12px;
  }
}

button {
  border: 0px;
  padding: 0px;
  margin: 0px;
  background: transparent;
}

#customized-select-native {
  padding-left: 40px;
}

// .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl {
//   margin: 0 20px 25px 0;
// }

@font-face {
  font-family: 'HarmoniaSansPro';
  src: url('/tenants/pampers/HarmoniaSansPro-SemiBd.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

.modalContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.6);
}

.notificationContainer {
  position: fixed;
  bottom: 50px;
  right: 70px;
  min-width: 200px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  -webkit-box-align: center;
  align-items: center;
  max-width: 98%;
  pointer-events: none;
  z-index: 2000;
  height: fit-content;
  background: transparent;
}

@include xsPhoneView {
  .notificationContainer {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    left: 0;
    right: 0;
    bottom: 100px;
    max-width: 100%;
    border-radius: 0px;
  }
  .profilePage {
    padding-top: 80px !important;
  }
}

.read-more-button {
  font-weight: bold;
  cursor: pointer;
}

// Profile page
.profilePageTitle {
  font-size: 36px;
  font-weight: 800;
  margin-bottom: 20px;
  color: $primaryFontColor;
}
.profilePageSubTitle {
  font-size: 16px;
  margin-bottom: 20px;
  color: $primaryFontColor;
}
.profilePage {
  // max-width: 1000px;
  background-color: $backgroundBase;
  // margin-bottom: 80px;
  padding: 20px;
  min-height: 100vh;
  margin-top: 80px;
  @include smTabletView {
    padding: 40px;
    margin-bottom: 0px;
  }
}

.profilePageHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.topLoader {
  position: fixed;
  top: 0;
  width: 100%;
  height: 5px;
  z-index: 1031;
}

// Loader for Buttons
.spinner {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid transparent;
  width: 16px !important;
  height: 16px !important;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Form Styles

.formErrors {
  font-family: $primaryFontFamily;
  font-size: $fontXSmall;
  color: $dangerColor;
  margin-left: 6px;
  max-width: 320px;
  margin-bottom: -18px;
}

.vizzuality__legend-item-container {
  background-color: $backgroundColor;
}
.vizzuality__c-timestep .vizzuality__player-btn > svg {
  fill: $primaryFontColor;
}

// .MuiSvgIcon-root {
// .MuiNativeSelect-icon {
//   path {
//     fill: $primaryFontColor;
//   }
// }
.MuiOutlinedInput-inputAdornedStart {
  padding-left: 0px !important;
}

.MuiStepLabel-label.MuiStepLabel-active,
.MuiStepLabel-label.MuiStepLabel-completed,
.MuiStepLabel-label {
  color: $primaryFontColor !important;
}
.MuiInputBase-input {
  color: $primaryFontColor;
}

.mapboxgl-ctrl-group {
  filter: $mapboxIcon;
}

input:autofill,
input:-webkit-autofill {
  background-color: $backgroundColorDark !important;
  color: $primaryFontColor !important;
}
.MuiSelect-icon,
.MuiNativeSelect-icon {
  fill: $primaryFontColor !important;
}
.MuiNativeSelect-select:not([multiple]) option,
.MuiNativeSelect-select:not([multiple]) optgroup {
  background-color: $backgroundColor !important;
}
.MuiAlert-message {
  color: $primaryFontColor !important;
}

.suggestions-container {
  position: absolute;
  z-index: 10;
  margin-top: 57px;
  color: $primaryFontColor;
  background-color: $backgroundColorDark;
  max-height: 200px;
  overflow: scroll;
  box-shadow: 0px 3px 6px -3px rgba($color: #000000, $alpha: 0.26);
  min-width: 100%;
  &.scientific-species {
    width: 100%;
    min-width: auto;
    max-width: 280px;
    .suggestion {
      font-style: italic;
    }
  }
  &.sampleTrees {
    width: 100%;
    max-width: 390px;
    min-width: auto;
    margin-top: 48px;
  }
  .suggestion {
    min-width: 100%;
    &:nth-child(even) {
      background-color: rgba(var(--background-color-dark), 0.6);
    }
    padding: 12px;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
    &:hover {
      cursor: pointer;
      background-color: $backgroundColorDark;
    }
  }
}

.project-form-flow {
  display: flex;
  justify-content: center;
  width: max-content;
}

@include xsPhoneView {
  .project-form-flow {
    flex-direction: column;
  }
  .tab-flow {
    max-width: 90vw;
    justify-content: center;
  }
  .primaryButton {
    margin-left: 0px;
  }
}

@include smTabletView {
  .project-form-flow {
    flex-direction: column;
    margin-top: 10px;
  }
}

@include mdTabletView {
  .project-form-flow {
    flex-direction: column;
    margin-top: 0px;
  }
}

@include lgLaptopView {
  .project-form-flow {
    flex-direction: row;
    margin-top: 0px;
  }
}

.notSubmitReview {
  width: 180px;
  margin-left: 10px;
  margin-top: 20px;
  @include mdTabletView {
    margin-top: 12px;
  }
  @include lgLaptopView {
    margin-top: 7px;
  }
}

.notSubmitExit {
  width: 150px;
  margin-left: 15px;
  margin-top: 20px;
  @include mdTabletView {
    margin-top: 12px;
  }
  @include lgLaptopView {
    margin-top: 7px;
  }
}

.add-projects-button {
  background-color: $backgroundColor;
  border: 1px solid #f3f3f3;
  cursor: pointer;
  width: 300px;
  height: 140px;
  padding: 20px;
  box-shadow: rgb(170 170 170 / 20%) 0px 1px 4px;
  border-radius: 10px;
}

.add-project {
  margin-top: 65px;
  margin-left: 90px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 140px;
  gap: 20px;
  justify-content: center;
  align-items: center;
  @include xsPhoneView {
    width: 100%;
    margin-left: -31px;
    top: 568px;
  }
  @include smTabletView {
    // width: 100%;
    margin-left: 5px;
    margin-top: 36px;
    top: 568px;
  }
  @include mdTabletView {
    flex-direction: row;
  }

  @include lgLaptopView {
    top: 208px;
    // margin-left: -126px;
  }

  @include xlDesktopView {
    margin-left: 45px;
  }
}

.project-selection {
  display: flex;
  margin-left: 0px;
  top: 0px;
  @include xsPhoneView {
    top: 491px;
  }
  @include smTabletView {
    top: 505px;
  }
  @include mdTabletView {
    flex-direction: column;
    margin-left: 265px;
    top: 160px;
  }
  @include lgLaptopView {
    margin-left: 70px;
    flex-direction: row;
  }
}

/* remove updown arrow on input type=number for  Chrome, Safari, Edge, Opera  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* remove updown arrow on input type=number for  Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.MuiStepper-horizontal {
  @include xsPhoneView {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
}

.tab-flow {
  display: flex;
  text-transform: capitalize !important;
  text-align: left;
  align-items: flex-start;
  width: 200px;
}

.tab-flow.Mui-selected {
  span {
    color: $primaryColor !important;
  }
}
.custom-tab {
  > .MuiTabs-scroller {
    > .PrivateTabIndicator-root-1 {
      width: 2px;
      height: 20px;
      top: 61px;
    }

    > .MuiTabs-flexContainer {
      > .MuiTab-root {
        padding-top: 0%;
        min-height: 35px;
      }

      > .MuiButtonBase-root {
        padding-bottom: 0%;
        align-items: baseline;

        > .MuiTab-wrapper:active {
          color: $primaryColor;
          text-decoration: none;
        }
        > .MuiTab-wrapper {
          align-items: flex-start;
          font-family: $primaryFontFamily;
          font-weight: 600;
          font-size: 14px;
          position: static;
          width: 124px;
          height: 20px;
          left: 18px;
          top: 0px;
          color: #828282;
        }
      }
    }

    > .MuiTabs-indicator {
      left: 0 !important;
      background-color: $primaryColor !important;
      height: 10px;
    }
  }
}

.impersonationAlertContainer {
  width: 100%;
  position: fixed;
  z-index: 1220;
}

.accessDeniedContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  h2 {
    margin-top: 24px;
    margin-left: 24px;
    font-weight: bold;
    font-size: 30px;
  }
}

.publicProfileMainContainer {
  max-width: 1400px;
  padding-left: 85px;
  @include xsPhoneView {
    padding-left: 0px;
  }
  @include smTabletView {
    padding-left: 0px;
  }
  @include mdTabletView {
    padding-left: 0px;
  }
  @include lgLaptopView {
    padding-left: 100px;
  }
}
